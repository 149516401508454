import {FormEventHandler, useCallback, useContext, useEffect, useMemo, useState} from "react";
import {add, isSameDay, format} from "date-fns";
import ManifestDatePicker from "../date-picker/ManifestDatePicker";
import styled from "@emotion/styled";
import {ManifestFilterType} from "../ManifestFilterState";
import {Constants, DateFormats} from "../../common/Constants";
import {StopStatusCategory} from "../types/StopStatusManifestFilter";
import {DateRange, DatePickerProps, DateRangePickerProps} from "@blueprintjs/datetime";
import {isValidDateTime, removeTimeFromDate} from "../../../utils/DateUtils";
import {ManifestTag} from "./ManifestTag";
import {ActiveIcon, FutureIcon, InactiveIcon} from "./ManifestIcons";
import {Switch} from "@blueprintjs/core";
import ManifestDateRangePicker from "../date-range-picker/ManifestDatePicker";
import {isArray} from "lodash";
import {NotOptimizedIcon, OptimizedIcon} from "../details/components/OptimizedStatus";
import {FeatureFlagContext} from "../../../providers/FeatureFlagProvider";

const Container = styled.div`
  width: 360px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  gap: 16px;
`;
const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
`;
const Row = styled.div<{isBetween?: boolean}>`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: ${(props) => (props.isBetween ? "space-between" : "flex-start")};
`;

const MainText = styled.div`
  font-size: 14px;
  font-weight: 500;
`;
const SubText = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export enum StatusType {
  ACTIVE = "Active",
  FUTURE = "Future",
  INACTIVE = "Inactive"
}

export enum OptimizedStatusTypes {
  OPTIMIZED = "Optimized",
  NOT_OPTIMIZED = "Not Optimized"
}

export enum DateSelection {
  All_DAY = "All Dates",
  TODAY_PREV = "Today & Previous",
  DATE_RANGE = "Date Range"
}

const isValidDateRange = (dateRange: any, allowUnixEpoch = true) => {
  if (!dateRange || !isArray(dateRange) || dateRange.some((date) => !isValidDateTime(date))) return false;
  if (!allowUnixEpoch) {
    return !dateRange.some((date) => isSameDay(date, new Date(0)));
  } else {
    return !!(dateRange[0] && dateRange[1]);
  }
};

const getDateRangeText = (dateRange: [Date, Date], dateFormat: string = DateFormats.localDateSlash) => {
  return `${format(dateRange[0], dateFormat)} - ${format(dateRange[1], dateFormat)}`;
};

export type ManifestFilterDatePickerProps = {
  selectedDateRange?: DateRange;
  onChangeDateRange: (dateRange: DateRange | undefined) => void;
  dateSelectionText: string;
  setDateSelectionText: React.Dispatch<React.SetStateAction<string>>;
  selectedStatusTypes: StatusType[];
  setSelectedStatusTypes: React.Dispatch<React.SetStateAction<StatusType[]>>;
  selectedOptimizedStatusTypes: OptimizedStatusTypes[];
  setSelectedOptimizedStatusTypes: React.Dispatch<React.SetStateAction<OptimizedStatusTypes[]>>;
  onFilterSelect: (
    filterType: ManifestFilterType.NONE | ManifestFilterType.MANIFEST_AGE | ManifestFilterType.STOP_STATUS,
    filterValue: string | number | undefined
  ) => void;
  isEmptyActived: boolean;
  setIsEmptyActived: React.Dispatch<React.SetStateAction<boolean>>;
  isNewActived: boolean;
  setIsNewActived: React.Dispatch<React.SetStateAction<boolean>>;
};

const today = new Date();

const ManifestFilter = ({
  selectedDateRange,
  onChangeDateRange,
  dateSelectionText,
  setDateSelectionText,
  selectedStatusTypes,
  setSelectedStatusTypes,
  selectedOptimizedStatusTypes,
  setSelectedOptimizedStatusTypes,
  onFilterSelect,
  isEmptyActived,
  setIsEmptyActived,
  isNewActived,
  setIsNewActived
}: ManifestFilterDatePickerProps) => {
  const [tempDateRange, setTempDateRange] = useState<[Date | null, Date | null]>([null, null]);
  const [tempDateSelectionText, setTempDateSelectionText] = useState<string>("");
  const {manifestOptimization} = useContext(FeatureFlagContext);

  const updateDateSelectionText = useCallback(
    (dateSelection: string, isSetTemp = false) => {
      setTempDateSelectionText(dateSelection);
      if (isSetTemp) return;
      setDateSelectionText(dateSelection);
    },
    [setDateSelectionText]
  );

  const onDateChange = useCallback(
    (date: Date | undefined | [Date | null, Date | null]) => {
      if (isArray(date)) {
        setTempDateRange(date);
        if (isValidDateRange(date, false)) {
          onChangeDateRange(date);
          updateDateSelectionText(DateSelection.DATE_RANGE);
        }
      } else {
        onChangeDateRange(date ? [date, date] : undefined);
        updateDateSelectionText("");
        setTempDateRange([null, null]);
      }
    },
    [onChangeDateRange, updateDateSelectionText]
  );

  const datePickerProps: DateRangePickerProps & DatePickerProps = useMemo(
    () => ({
      dayPickerProps: {
        firstDayOfWeek: 1,
        weekdaysShort: ["S", "M", "T", "W", "T", "F", "S"]
      },
      shortcuts: false,
      maxDate: add(today, {months: 1}),
      contiguousCalendarMonths: false,
      singleMonthOnly: true,
      allowSingleDayRange: true,
      highlightCurrentDay: true,
      onChange: onDateChange
    }),
    [onDateChange]
  );

  const onStatusClick = (status: StatusType) => {
    const updatedStatusTypes = [...selectedStatusTypes, status];
    setSelectedStatusTypes(updatedStatusTypes);
  };

  const onRemoveStatusClick = (status: StatusType) => {
    const remainingStatusTypes = selectedStatusTypes.filter((s) => s !== status);
    setSelectedStatusTypes(remainingStatusTypes);
  };

  const onOptimizationStatusClick = (status: OptimizedStatusTypes) => {
    const updatedOptimizedStatusTypes = [...selectedOptimizedStatusTypes, status];
    setSelectedOptimizedStatusTypes(updatedOptimizedStatusTypes);
  };

  const onRemoveOptimizationStatusClick = (optimizedStatus: OptimizedStatusTypes) => {
    const remainingOptimizedStatusTypes = selectedOptimizedStatusTypes.filter((s) => s !== optimizedStatus);
    setSelectedOptimizedStatusTypes(remainingOptimizedStatusTypes);
  };

  const onDateSelectionClick = (selectionText: DateSelection) => {
    if (selectionText === tempDateSelectionText && selectionText === DateSelection.DATE_RANGE) {
      updateDateSelectionText("");
      onChangeDateRange(undefined);
      setTempDateRange([null, null]);
      return;
    }
    switch (selectionText) {
      case DateSelection.All_DAY:
        onChangeDateRange(undefined);
        break;
      case DateSelection.TODAY_PREV:
        onChangeDateRange([null, removeTimeFromDate(today)]);
        break;
      case DateSelection.DATE_RANGE:
        setTempDateRange([null, null]);
        updateDateSelectionText(DateSelection.DATE_RANGE, true);
        break;
    }
    if (selectionText !== DateSelection.DATE_RANGE) {
      updateDateSelectionText(selectionText);
      setTempDateRange([null, null]);
    }
  };

  const onEmptyChanged: FormEventHandler<HTMLInputElement> = (event) => {
    onFilterSelect(ManifestFilterType.STOP_STATUS, StopStatusCategory.COMPLETE);
    setIsEmptyActived(event.currentTarget.checked);
  };

  const onNewChanged: FormEventHandler<HTMLInputElement> = (event) => {
    onFilterSelect(ManifestFilterType.MANIFEST_AGE, Constants.MANIFEST_NEW_AGE);
    setIsNewActived(event.currentTarget.checked);
  };

  useEffect(() => {
    if (isNewActived || isEmptyActived) return;
    onFilterSelect(ManifestFilterType.NONE, undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewActived, isEmptyActived]);

  useEffect(() => {
    if (dateSelectionText === DateSelection.DATE_RANGE && isValidDateRange(selectedDateRange, false)) {
      setTempDateRange(selectedDateRange!);
    }
    updateDateSelectionText(dateSelectionText, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container data-testid="manifest-filter-container">
      <Block>
        <MainText>Status</MainText>
        <Row>
          <ManifestTag
            text={StatusType.ACTIVE}
            hasCloseIcon={true}
            icon={<ActiveIcon />}
            onClick={() => onStatusClick(StatusType.ACTIVE)}
            onClickCloseIcon={() => onRemoveStatusClick(StatusType.ACTIVE)}
            isSelected={selectedStatusTypes.includes(StatusType.ACTIVE)}
          />
          <ManifestTag
            text={StatusType.FUTURE}
            hasCloseIcon={true}
            icon={<FutureIcon />}
            onClick={() => onStatusClick(StatusType.FUTURE)}
            onClickCloseIcon={() => onRemoveStatusClick(StatusType.FUTURE)}
            isSelected={selectedStatusTypes.includes(StatusType.FUTURE)}
          />
          <ManifestTag
            text={StatusType.INACTIVE}
            hasCloseIcon={true}
            icon={<InactiveIcon />}
            onClick={() => onStatusClick(StatusType.INACTIVE)}
            onClickCloseIcon={() => onRemoveStatusClick(StatusType.INACTIVE)}
            isSelected={selectedStatusTypes.includes(StatusType.INACTIVE)}
          />
        </Row>
      </Block>
      {manifestOptimization && (
        <Block data-testid="optimize-status-block">
          <MainText>Optimization Status</MainText>
          <Row>
            <ManifestTag
              text={OptimizedStatusTypes.OPTIMIZED}
              hasCloseIcon={true}
              icon={<OptimizedIcon />}
              onClick={() => onOptimizationStatusClick(OptimizedStatusTypes.OPTIMIZED)}
              onClickCloseIcon={() => onRemoveOptimizationStatusClick(OptimizedStatusTypes.OPTIMIZED)}
              isSelected={selectedOptimizedStatusTypes.includes(OptimizedStatusTypes.OPTIMIZED)}
            />
            <ManifestTag
              text={OptimizedStatusTypes.NOT_OPTIMIZED}
              hasCloseIcon={true}
              icon={<NotOptimizedIcon />}
              onClick={() => onOptimizationStatusClick(OptimizedStatusTypes.NOT_OPTIMIZED)}
              onClickCloseIcon={() => onRemoveOptimizationStatusClick(OptimizedStatusTypes.NOT_OPTIMIZED)}
              isSelected={selectedOptimizedStatusTypes.includes(OptimizedStatusTypes.NOT_OPTIMIZED)}
            />
          </Row>
        </Block>
      )}
      <Block>
        <MainText>Date</MainText>
        <Row>
          <ManifestTag
            text={DateSelection.All_DAY}
            hasCloseIcon={false}
            isSelected={tempDateSelectionText === DateSelection.All_DAY}
            onClick={() => onDateSelectionClick(DateSelection.All_DAY)}
          />
          <ManifestTag
            text={DateSelection.TODAY_PREV}
            hasCloseIcon={false}
            isSelected={tempDateSelectionText === DateSelection.TODAY_PREV}
            onClick={() => onDateSelectionClick(DateSelection.TODAY_PREV)}
          />
          <ManifestTag
            text={
              isValidDateRange(tempDateRange, false)
                ? getDateRangeText([tempDateRange[0]!, tempDateRange[1]!])
                : DateSelection.DATE_RANGE
            }
            hasCloseIcon={false}
            isSelected={tempDateSelectionText === DateSelection.DATE_RANGE}
            onClick={() => onDateSelectionClick(DateSelection.DATE_RANGE)}
          />
        </Row>
        {tempDateSelectionText === DateSelection.DATE_RANGE ? (
          <ManifestDateRangePicker {...datePickerProps} value={tempDateRange} />
        ) : (
          <ManifestDatePicker
            {...datePickerProps}
            value={
              isValidDateRange(selectedDateRange) && isSameDay(selectedDateRange![0]!, selectedDateRange![1]!)
                ? selectedDateRange![1]
                : undefined
            }
          />
        )}
      </Block>
      <Block>
        <Row isBetween={true}>
          <SubText>Show only empty manifests</SubText>
          <Switch data-testid="empty-switch" checked={isEmptyActived} large={true} onChange={onEmptyChanged} />
        </Row>
        <Row isBetween={true}>
          <SubText>Show only new manifests</SubText>
          <Switch data-testid="new-switch" checked={isNewActived} large={true} onChange={onNewChanged} />
        </Row>
      </Block>
    </Container>
  );
};
export default ManifestFilter;
