import {Manifest, ManifestStop} from "../../../generated/graphql";
import {isCompleted} from "../../../services/JobStopService";
import {getTimezoneOffset, toDate, utcToZonedTime} from "date-fns-tz";
import {getIANATimezone} from "../../../utils/DateUtils";
import {addMinutes, startOfDay} from "date-fns";
import {Constants} from "../../common/Constants";
import {getStopTimeForType} from "../../../utils/StopTime";

export const optimizeManifest = (manifest: Manifest) => {
  console.debug("Optimizing %s", manifest.manifestDriverId);
};

export const findNotCompletedStops = (manifestStops: ManifestStop[] | undefined | null) => {
  if (!manifestStops) {
    return [];
  }
  return manifestStops.filter((stop) => !isCompleted(stop));
};

export const findEarliestStopDateV2 = (
  manifestStops: ManifestStop[] | undefined | null,
  options: {timezoneOfStartLocation?: string; timeZoneOfJobStopEnabled: boolean}
) => {
  const notCompletedStops = findNotCompletedStops(manifestStops);

  if (notCompletedStops.length === 0) return new Date();

  const sortedDatesAfterToday = notCompletedStops
    .filter((stop) => toDate(getStopTimeForType(stop, stop.stopType) ?? new Date()).getTime() > new Date().getTime())
    .map((stop) => ({stopDateTime: getStopTimeForType(stop, stop.stopType)!, timeZone: stop.timeZone!}))
    .sort((a, b) => new Date(a.stopDateTime).getTime() - new Date(b.stopDateTime).getTime());

  if (sortedDatesAfterToday.length === 0) return new Date();

  const earliestStopDateTime = new Date(sortedDatesAfterToday[0].stopDateTime);
  if (!options.timeZoneOfJobStopEnabled) return earliestStopDateTime;

  const earliestTimeZone = sortedDatesAfterToday[0].timeZone;
  const convertEarliestDateByTimeZone = utcToZonedTime(earliestStopDateTime, getIANATimezone(earliestTimeZone));

  if (options.timezoneOfStartLocation) {
    const earliestTimezoneOffsets = getTimezoneOffset(getIANATimezone(earliestTimeZone)) / (60 * 1000);
    const offsetMinutesBetweenEarliestAndBrowser = earliestTimezoneOffsets - Constants.BROWSER_TIMEZONE_OFFSET_MINUTES;
    const convertedScheduleByTZ = addMinutes(new Date(earliestStopDateTime), offsetMinutesBetweenEarliestAndBrowser);

    const startTime = startOfDay(convertedScheduleByTZ);
    const jobStopTimezoneOffsetStartLocation =
      getTimezoneOffset(getIANATimezone(options.timezoneOfStartLocation)) / (60 * 1000);
    const offsetMinutesBetweenStartAndEarliest = jobStopTimezoneOffsetStartLocation - earliestTimezoneOffsets;
    return addMinutes(startTime, offsetMinutesBetweenStartAndEarliest);
  } else {
    return convertEarliestDateByTimeZone;
  }
};

export enum GRAPHHOPPER_INVALID_MSG {
  ADDRESS = "Route optimization request contains invalid addresses."
}
