import React from "react";
import styled from "@emotion/styled";
import {Tag, Text} from "@blueprintjs/core";
import {useAppContext} from "../../../../ApplicationContext";
import {isValidDateTime} from "../../../../utils/DateUtils";
import {Tooltip2} from "@blueprintjs/popover2";

interface IOptimizedStatus {
  optimizedDateTime: string;
  optimizedBy: string;
}

const options: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  minute: "2-digit"
};

const OptimizedStatus = ({optimizedBy, optimizedDateTime}: IOptimizedStatus) => {
  const {appState} = useAppContext();
  options.hour12 = !appState.isTimeFormat24hr;
  const isOptimized = isValidDateTime(optimizedDateTime);
  const formattedDateTime = new Date(optimizedDateTime)
    .toLocaleString("en-US", options)
    .replace(",", "")
    .replace(" PM", "pm")
    .replace(" AM", "am");
  return (
    <Container>
      <Tooltip2
        popoverClassName="white-popover optimized-status-popover"
        content={
          <span>
            A manifest will remain in the optimized status until a meaningful change happens to the manifest via{" "}
            <b>Dispatch</b>
          </span>
        }
        disabled={!isOptimized}
      >
        <ManifestOptimizedStatusTag
          isOptimized={isOptimized}
          icon={isOptimized ? <OptimizedIcon /> : <NotOptimizedIcon />}
          round
        >
          {isOptimized ? "Optimized" : "Not Optimized"}
        </ManifestOptimizedStatusTag>
      </Tooltip2>
      {isOptimized && (
        <StyledText ellipsize>
          on {formattedDateTime} · by {optimizedBy}
        </StyledText>
      )}
    </Container>
  );
};

export default OptimizedStatus;

const Container = styled.div`
  font-family: "Roboto", sans-serifs;
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: hidden;
`;

const StyledText = styled(Text)`
  font-size: 12px;
  color: #797979;
  font-weight: 500;
`;

const ManifestOptimizedStatusTag = styled(Tag)<{isOptimized?: boolean}>`
  min-width: fit-content;
  background-color: ${(props) => (props.isOptimized ? "#d2f3da" : "#F3F4F6")};
  color: ${(props) => (props.isOptimized ? "#32a467" : "#797979")};
  font-size: 12px;
  padding: 4px 4px;
  font-weight: 500;
`;

export const NotOptimizedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.8667 15.1333L11.9334 13.2L12.1334 14L8.00006 11.5333L3.86673 14L4.9334 9.3333L1.3334 6.1333L4.60007 5.86663L0.733398 1.99997L1.60007 1.1333L14.7334 14.2666L13.8667 15.1333ZM14.6667 6.1333L9.86673 5.7333L8.00006 1.3333L6.66673 4.5333L11.2667 9.1333L14.6667 6.1333Z"
      fill="#6B7280"
    />
  </svg>
);

export const OptimizedIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.99967 11.5133L12.1197 14L11.0263 9.31331L14.6663 6.15998L9.87301 5.74665L7.99967 1.33331L6.12634 5.74665L1.33301 6.15998L4.96634 9.31331L3.87967 14L7.99967 11.5133Z"
      fill="#32A467"
    />
  </svg>
);
