import {ManifestModalDialog} from "../ManifestModalDialog";
import ManifestFilter, {ManifestFilterDatePickerProps} from "./ManifestFilter";

export type ManifestFilterDialogProps = ManifestFilterDatePickerProps & {
  onClose: () => void;
};

const ManifestFilterDialog = (props: ManifestFilterDialogProps) => {
  return (
    <ManifestModalDialog id="filter-manifest" onClose={props.onClose}>
      <ManifestFilter {...props} />
    </ManifestModalDialog>
  );
};

export default ManifestFilterDialog;
