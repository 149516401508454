import {Dispatch, useCallback, useState} from "react";
import {CardType} from "../../../views/JobAssignmentView";
import {
  JobAssignmentViewState,
  JobViewActions,
  ManifestFilterTypePayload
} from "../../../views/JobAssignmentViewReducer";
import {JobAssignmentState, JobAssignmentActions, JobAssignmentAsyncActions} from "../../common/JobAssignmentReducer";
import ManifestDataProvider from "../ManifestDataProvider";
import AssignDriverContainer from "./components/AssignDriverContainer";
import {ManifestFilterType} from "../ManifestFilterState";
import {DateRange} from "@blueprintjs/datetime2";
import {ManifestDetailsState, ManifestDetailsActions} from "./ManifestDetailsReducer";
import {OptimizedStatusTypes, StatusType} from "../manifest-filter/ManifestFilter";
import {buildManifestStatusFilter, buildOptimizedStatusFilter} from "../../../views/UnassignedJobsView";
import {makeQueryStringFilter} from "../../../utils/QueryUtils";
import ManifestDriverPanel from "../ManifestDriverPanel";
import {ManifestFilter} from "../../../generated/graphql";

type ManifestDetailsManifestSelectorProps = {
  manifestDetailsState: ManifestDetailsState;
  manifestDetailsDispatch: Dispatch<ManifestDetailsActions>;
  jobAssignmentState: JobAssignmentState;
  jobAssignmentDispatch: Dispatch<JobAssignmentActions | JobAssignmentAsyncActions>;
  jobAssignmentViewState: JobAssignmentViewState;
  jobAssignmentViewStateDispatch: Dispatch<JobViewActions>;
  onChangedView(type: CardType): void;
  highlightQualifiedDrivers: boolean;
};

const ManifestDetailsManifestSelector = ({
  manifestDetailsState,
  manifestDetailsDispatch,
  jobAssignmentState,
  jobAssignmentDispatch,
  jobAssignmentViewState,
  jobAssignmentViewStateDispatch,
  onChangedView,
  highlightQualifiedDrivers
}: ManifestDetailsManifestSelectorProps) => {
  const [statusTypes, setStatusTypes] = useState<StatusType[]>([]);
  const [selectedOptimizedStatusTypes, setSelectedOptimizedStatusTypes] = useState<OptimizedStatusTypes[]>([]);

  const onFilterSelected = useCallback(
    (filterState: ManifestFilterTypePayload[], oldFilterState?: ManifestFilterTypePayload[]) => {
      const payload = () => {
        let state = manifestDetailsState.assignDriverManifestFilterState!;
        if (oldFilterState) {
          state = state.clearManifestFilterTypes(oldFilterState.map((f) => f.filterType));
        }
        if (filterState.length === 0) {
          state = state.addManifestFilterValue(undefined, ManifestFilterType.NONE);
        } else {
          state = state.addManifestFilterValues(filterState);
        }

        return state.copy();
      };
      manifestDetailsDispatch({
        type: "SET_ASSIGNDRIVER_MANIFEST_FILTER_STATE",
        payload: payload()
      });
    },
    [manifestDetailsDispatch, manifestDetailsState.assignDriverManifestFilterState]
  );

  const onFilterDateChanged = useCallback(
    (dateRange: DateRange | undefined, selectedDateRange: DateRange | undefined) => {
      if (dateRange !== selectedDateRange) {
        const payload = () => {
          const state = manifestDetailsState.assignDriverManifestFilterState!.replaceManifestFilterValue(
            dateRange,
            ManifestFilterType.DATE_RANGE
          );
          return state.copy();
        };
        manifestDetailsDispatch({
          type: "SET_ASSIGNDRIVER_MANIFEST_FILTER_STATE",
          payload: payload()
        });
      }
    },
    [manifestDetailsDispatch, manifestDetailsState.assignDriverManifestFilterState]
  );

  const onSelectDriverGroup = useCallback(
    (key: number, arrayOfManifestFilter: React.MutableRefObject<number[]>) => {
      arrayOfManifestFilter.current.push(key);
      const payload = () => {
        const state = manifestDetailsState.assignDriverManifestFilterState!.addManifestFilterValue(
          key,
          ManifestFilterType.DRIVER_GROUP
        );
        return state.copy();
      };
      manifestDetailsDispatch({
        type: "SET_ASSIGNDRIVER_MANIFEST_FILTER_STATE",
        payload: payload()
      });
    },
    [manifestDetailsDispatch, manifestDetailsState.assignDriverManifestFilterState]
  );

  const onUnselectDriverGroup = useCallback(
    (key: number, arrayOfManifestFilter: React.MutableRefObject<number[]>) => {
      arrayOfManifestFilter.current = arrayOfManifestFilter.current.filter((d) => d !== key);

      const payload = () => {
        const state = manifestDetailsState.assignDriverManifestFilterState!.removeManifestFilterValue(
          key,
          ManifestFilterType.DRIVER_GROUP
        );
        return state.copy();
      };
      manifestDetailsDispatch({
        type: "SET_ASSIGNDRIVER_MANIFEST_FILTER_STATE",
        payload: payload()
      });
    },
    [manifestDetailsDispatch, manifestDetailsState.assignDriverManifestFilterState]
  );

  const onUnselectAllDriverGroup = useCallback(() => {
    const payload = () => {
      const state = manifestDetailsState.assignDriverManifestFilterState!.clearManifestFilterType(
        ManifestFilterType.DRIVER_GROUP
      );
      return state.copy();
    };
    manifestDetailsDispatch({
      type: "SET_ASSIGNDRIVER_MANIFEST_FILTER_STATE",
      payload: payload()
    });
  }, [manifestDetailsDispatch, manifestDetailsState.assignDriverManifestFilterState]);

  const onHandleManifestQuery = useCallback(
    (query: string) => {
      manifestDetailsDispatch({type: "SET_ASSIGNDRIVER_MANIFEST_QUERY", payload: query});
    },
    [manifestDetailsDispatch]
  );
  const buildFilter = useCallback(() => {
    const result: ManifestFilter = {
      manifest: {
        and: []
      }
    };
    buildManifestStatusFilter(result, statusTypes);
    buildOptimizedStatusFilter(result, selectedOptimizedStatusTypes);
    manifestDetailsState.assignDriverManifestFilterState?.appendToFilter(result);
    if (manifestDetailsState.assignDrvierManifestQuery) {
      result.queryString = makeQueryStringFilter(manifestDetailsState.assignDrvierManifestQuery, [
        "driver.name",
        "driver.code",
        "manifestDriverId",
        "stops.job.jobNumber",
        "stops.job.routeNumber"
      ]);
    }
    return result;
  }, [
    manifestDetailsState.assignDriverManifestFilterState,
    manifestDetailsState.assignDrvierManifestQuery,
    selectedOptimizedStatusTypes,
    statusTypes
  ]);

  return (
    <ManifestDataProvider
      filter={buildFilter()}
      sort={{
        field: jobAssignmentViewState.manifestSort.property,
        direction: jobAssignmentViewState.manifestSort.sortDirection
      }}
    >
      <AssignDriverContainer
        assignmentState={jobAssignmentState}
        assignmentDispatch={jobAssignmentDispatch}
        highlightRequirementManifests={highlightQualifiedDrivers}
      >
        <ManifestDriverPanel
          statusTypes={statusTypes}
          setStatusTypes={setStatusTypes}
          selectedOptimizedStatusTypes={selectedOptimizedStatusTypes}
          setSelectedOptimizedStatusTypes={setSelectedOptimizedStatusTypes}
          hideCreateManifestButton={true}
          assignmentState={jobAssignmentState}
          state={jobAssignmentViewState}
          assignmentDispatch={jobAssignmentDispatch}
          dispatch={jobAssignmentViewStateDispatch}
          onChangedView={onChangedView}
          onManifestDetailsDrawerOpen={() => {
            //This is already in the drawer so we don't need to do anything
          }}
          filterSelected={onFilterSelected}
          filterDateChanged={onFilterDateChanged}
          selectDriverGroup={onSelectDriverGroup}
          unselectDriverGroup={onUnselectDriverGroup}
          unselectAllDriverGroup={onUnselectAllDriverGroup}
          handleManifestQuery={onHandleManifestQuery}
          disabledManifestIds={manifestDetailsState.disabledManifestIds}
        />
      </AssignDriverContainer>
    </ManifestDataProvider>
  );
};

export default ManifestDetailsManifestSelector;
export type {ManifestDetailsManifestSelectorProps};
