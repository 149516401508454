import React, {useCallback, useContext, useEffect, useMemo, useState} from "react";
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import "./TimePickerCommon.css";
import styled from "@emotion/styled";
import {combineDateAndTime, convertDateWithTimeZone, setPlaceHolderTimePicker} from "../../../../utils/General";
import {Button, Checkbox} from "@blueprintjs/core";
import {extractJsonPref, PreferenceContext} from "../../../../providers/PreferenceProvider";
import {Constants, TenantPreferences} from "../../../common/Constants";
import {getTimezoneOffset, utcToZonedTime} from "date-fns-tz";
import {getIANATimezone} from "../../../../utils/DateUtils";
import {addMinutes, isValid, startOfDay} from "date-fns";
import {Popover2} from "@blueprintjs/popover2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";

export interface TimePickerProps {
  onChange: (date: Date | null) => void;
  earliestDate?: Date;
  timeZoneAddress?: string;
}
const Disabled_Text = "#777777";
const Active_Text = "#14305A";

const TimePickerCommon: React.FC<TimePickerProps> = ({timeZoneAddress, onChange, earliestDate}) => {
  const [timeValue, setTimeValue] = useState<Date | null | undefined>(null);
  const [currentTimeChecked, setCurrentTimeChecked] = useState<boolean>(false);
  const [placeholderTime, setPlaceholderTime] = useState<Date | undefined>(new Date());
  const [openTimePicker, setOpenTimePicker] = useState<boolean>(false);
  const {tenantPreferences} = useContext(PreferenceContext);
  const [openInvalidTimePopup, setOpenInvalidTimePopup] = useState<boolean>(false);
  const [hideCurrentTimeCheckbox, setHideCurrentTimeCheckbox] = useState<boolean>(false);
  const isTimeZoneOfJobStopEnabled = useMemo(() => {
    return extractJsonPref(tenantPreferences, TenantPreferences.timezoneOfJobStop, false).value.value;
  }, [tenantPreferences]);

  const handleChangeTime = useCallback(
    (e: Date | null | undefined) => {
      if (e) {
        setTimeValue(e);
        if (isTimeZoneOfJobStopEnabled) {
          if (!timeZoneAddress) {
            return;
          }
          const combinedTime = combineDateAndTime(e, earliestDate);
          const earliestTimezoneOffsets = getTimezoneOffset(getIANATimezone(timeZoneAddress)) / (60 * 1000);
          const offsetBetweenEarliestAndBrowser = Constants.BROWSER_TIMEZONE_OFFSET_MINUTES - earliestTimezoneOffsets;
          onChange(addMinutes(combinedTime, offsetBetweenEarliestAndBrowser));
        } else {
          onChange(combineDateAndTime(e, earliestDate));
        }
      }
    },
    [earliestDate, isTimeZoneOfJobStopEnabled, timeZoneAddress, onChange]
  );

  const handleOpenTimePicker = useCallback(() => {
    setOpenTimePicker(true);
  }, []);

  const handleCloseTimePicker = useCallback(() => {
    setOpenTimePicker(false);
  }, []);

  const handleOk = useCallback(() => {
    if (timeValue) {
      onChange(combineDateAndTime(timeValue, earliestDate));
      setOpenTimePicker(false);
    }
  }, [onChange, timeValue, earliestDate]);

  const handleClear = useCallback(() => {
    setOpenTimePicker(false);
    if (timeZoneAddress) {
      const dateValue = convertDateWithTimeZone(new Date(), timeZoneAddress) as Date;
      setPlaceHolderTimePicker(dateValue);
      if (timeValue) {
        setTimeValue(null);
        onChange(null);
      }
    } else {
      setPlaceholderTime(new Date());
      setTimeValue(null);
      onChange(null);
    }
  }, [onChange, timeZoneAddress, timeValue]);

  const handleCurrentTimeChange = useCallback((e) => {
    setCurrentTimeChecked(e.target.checked);
  }, []);

  useEffect(() => {
    const setNoValue = () => {
      setPlaceholderTime(new Date());
      onChange(null);
      setTimeValue(null);
    };

    const setDate = (date: Date) => {
      setTimeValue(date);
      onChange(combineDateAndTime(new Date(), earliestDate));
    };
    if (currentTimeChecked) {
      if (isTimeZoneOfJobStopEnabled) {
        if (!timeZoneAddress) {
          setCurrentTimeChecked(false);
          setNoValue();
          return;
        }
        const timeValueWithTimeZone = utcToZonedTime(new Date(), getIANATimezone(timeZoneAddress!));
        const combinedTime = combineDateAndTime(timeValueWithTimeZone, earliestDate);
        const earliestTimezoneOffsets = getTimezoneOffset(getIANATimezone(timeZoneAddress)) / (60 * 1000);
        const offsetBetweenEarliestAndBrowser = Constants.BROWSER_TIMEZONE_OFFSET_MINUTES - earliestTimezoneOffsets;
        setTimeValue(timeValueWithTimeZone);
        onChange(addMinutes(combinedTime, offsetBetweenEarliestAndBrowser));
      } else {
        setDate(new Date());
      }
    } else {
      setNoValue();
    }
  }, [currentTimeChecked, isTimeZoneOfJobStopEnabled, onChange, timeZoneAddress, earliestDate]);

  const renderActionBar = useCallback(() => {
    return <ActionBar handleClear={handleClear} handleOk={handleOk} active={Boolean(timeValue)} />;
  }, [handleClear, handleOk, timeValue]);

  const shouldDisableCurrentTimeCheckbox = useMemo(() => {
    return isTimeZoneOfJobStopEnabled && !timeZoneAddress;
  }, [isTimeZoneOfJobStopEnabled, timeZoneAddress]);

  const handleBlurField = useCallback(() => {
    if (timeValue && !isValid(timeValue)) {
      setOpenInvalidTimePopup(true);
    }
  }, [timeValue]);

  const handleClosePopOver = useCallback(() => {
    setOpenInvalidTimePopup(false);
  }, []);

  useEffect(() => {
    setHideCurrentTimeCheckbox(startOfDay(earliestDate ?? new Date()).getTime() > startOfDay(new Date()).getTime());
  }, [earliestDate]);

  return (
    <StartDateContainer>
      <Popover2
        isOpen={openInvalidTimePopup}
        content={
          <InvalidPopOverContent text={"Please enter a valid start time."} onClosePopOver={handleClosePopOver} />
        }
        onClose={handleClosePopOver}
        interactionKind="click-target"
        hasBackdrop={true}
        placement="top"
      >
        <TimePickerContainer data-testid="time-picker-container">
          <TimePicker
            className="optimization-time-picker"
            data-testid={"time-picker"}
            ampm
            value={timeValue}
            onChange={handleChangeTime}
            open={openTimePicker}
            onClose={handleCloseTimePicker}
            closeOnSelect={false}
            onAccept={handleOk}
            slots={{
              actionBar: renderActionBar
            }}
            slotProps={{
              field: {onBlur: handleBlurField},
              textField: {placeholder: setPlaceHolderTimePicker(placeholderTime)}
            }}
          />
          <div data-testid="time-picker-right" className="time-picker-right" onClick={handleOpenTimePicker}></div>
        </TimePickerContainer>
      </Popover2>
      {!hideCurrentTimeCheckbox && (
        <StyledCheckbox
          data-testid="current-time-checkbox"
          checked={currentTimeChecked}
          label="Current Time"
          onChange={handleCurrentTimeChange}
          disabled={shouldDisableCurrentTimeCheckbox}
        />
      )}
    </StartDateContainer>
  );
};

export default TimePickerCommon;
interface ActionBarProps {
  handleOk: () => void;
  handleClear: () => void;
  active: boolean;
}

const ActionBar = ({handleClear, handleOk, active}: ActionBarProps) => {
  return (
    <ActionBarContainer data-testid="action-bar">
      <ClearButton data-testid="clear-button" onClick={handleClear} active={active}>
        Clear
      </ClearButton>
      <OKButton data-testid="ok-button" onClick={handleOk} active={active}>
        OK
      </OKButton>
    </ActionBarContainer>
  );
};
ActionBar.displayName = "ActionBar";

const StartDateContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
`;

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 0;
  &.bp4-control input:checked ~ .bp4-control-indicator {
    background-color: ${Active_Text} !important;
  }
`;

const TimePickerContainer = styled.div`
  background-color: #fff;
  position: relative;
  width: 184px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid #c3c3c36e;
  padding-left: 12px;
`;

const ActionBarContainer = styled.div`
  width: 170px;
  padding: 16px 22px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  background-color: white;
  bottom: -52px;
  left: -1px;
  border-radius: 0px 0px 4px 4px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
    0px 3px 14px 2px rgba(0, 0, 0, 0.12);
`;
interface ButtonProps {
  active: boolean;
}
const ClearButton = styled.p<ButtonProps>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: justified;
  margin: 0;
  cursor: pointer;
  color: ${(props) => (props.active ? Active_Text : Disabled_Text)};
`;

export const OKButton = styled.p<ButtonProps>`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  text-align: left;
  margin: 0;
  cursor: pointer;
  color: ${(props) => (props.active ? Active_Text : Disabled_Text)};
`;

interface InvalidPopOverContentProps {
  text: string;
  onClosePopOver: () => void;
}

export const InvalidPopOverContent: React.FC<InvalidPopOverContentProps> = ({text, onClosePopOver}) => {
  return (
    <Container>
      <Body>
        <FontAwesomeIcon icon={solid("circle-info")} color={"#FF0000"} size={"lg"} />
        <Text>{text}</Text>
      </Body>

      <Action>
        <CloseButton onClick={onClosePopOver}>Okay</CloseButton>
      </Action>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
`;
const Body = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
const Text = styled.div``;
const Action = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
const CloseButton = styled(Button)`
  .bp4-button-text {
    color: #ffffff;
  }
  background: linear-gradient(rgb(33, 74, 137) 0%, rgb(20, 48, 90) 100%);
`;
