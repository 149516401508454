import styled from "@emotion/styled";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {
  DialogBody,
  DialogCloseIcon,
  StyledDialog,
  DialogHeader,
  DialogText,
  DialogTitle,
  distanceFromLeftOptimizeDialog,
  DialogContainer,
  Footer
} from "./OptimizeDialog";
import {InvalidAddresses, capitalizeWords} from "../../../../utils/General";
import {
  GraphHopperInvalidLocationMess,
  GraphHopperInvalidStopMess,
  GraphHopperValidStopMess
} from "../ManifestDetailsV2";
import {ActionButton} from "../../../common/ConfirmationDialog";
import {AnchorButton} from "@blueprintjs/core";
import {GRAPHHOPPER_INVALID_MSG} from "../../../map/optimization/OptimizationService";

export interface ErrorDialogRefs {
  handleClose: () => void;
  handleOpen: () => void;
}
export interface ErrorDialogProps {
  typeError: "" | "googleGeo" | "graphhopper" | "genetic" | "graphhopper_timeout";
  invalidAddresses: Array<InvalidAddresses>;
  graphHopperInvalidStopMess: Array<GraphHopperInvalidStopMess>;
  graphHopperValidStopMess: GraphHopperValidStopMess[][];
  graphHopperGeneticMess: string;
  graphHopperInvalidLocationMess: GraphHopperInvalidLocationMess[];
}
const ErrorDialog = forwardRef<ErrorDialogRefs, ErrorDialogProps>(
  (
    {
      typeError,
      invalidAddresses,
      graphHopperInvalidStopMess,
      graphHopperValidStopMess,
      graphHopperGeneticMess,
      graphHopperInvalidLocationMess
    },
    ref
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const handleClose = useCallback(() => {
      setIsOpen(false);
    }, []);

    const renderTitle = () => {
      if (typeError === "googleGeo") {
        return "ADDRESS(ES) INVALID";
      }
      return "MANIFEST OPTIMIZATION UNSUCCESSFUL";
    };
    const handleOpen = useCallback(() => {
      setIsOpen(true);
    }, []);
    useImperativeHandle(ref, () => ({
      handleClose,
      handleOpen
    }));
    const renderContent = () => {
      if (typeError === "googleGeo") return <InvalidAddressesContent invalidAddresses={invalidAddresses} />;
      if (typeError === "graphhopper")
        return (
          <InvalidGraphhopper
            graphHopperValidMess={graphHopperValidStopMess}
            graphHopperInvalidMess={graphHopperInvalidStopMess}
            graphHopperInvalidLocationMess={graphHopperInvalidLocationMess}
          />
        );
      if (typeError === "graphhopper_timeout") {
        return (
          <InvalidContent data-testid="gh-timeout">
            <DialogText primary>
              There was an issue when receiving the response from our optimization engine. Please reach out to
              <AnchorButton
                intent="primary"
                href="mailto:support@e-courier.com"
                rightIcon="share"
                text="support@e-courier.com"
                minimal={true}
              ></AnchorButton>
              or create a help center ticket if this issue persists.
            </DialogText>
          </InvalidContent>
        );
      }
      return <GeneticError graphHopperGeneticMess={graphHopperGeneticMess} />;
    };
    return (
      <StyledDialog isOpen={isOpen} onClose={handleClose} distanceFromLeft={distanceFromLeftOptimizeDialog()}>
        <DialogContainer>
          <DialogHeader>
            <DialogTitle data-testid="dialog-title">{renderTitle()}</DialogTitle>
            <DialogCloseIcon minimal data-testid="dialog-close-icon" onClick={handleClose} icon="cross" />
          </DialogHeader>
          <DialogBody>{renderContent()}</DialogBody>
          <Footer>
            <ActionButton data-testid="dialog-cancel-button" onClick={handleClose}>
              Cancel
            </ActionButton>
          </Footer>
        </DialogContainer>
      </StyledDialog>
    );
  }
);
ErrorDialog.displayName = "ErrorDialog";
export default ErrorDialog;
interface InvalidAddressesProps {
  invalidAddresses: Array<InvalidAddresses>;
}
const InvalidAddressesContent = ({invalidAddresses}: InvalidAddressesProps) => {
  const filteredInvalidLocation = invalidAddresses.filter((item) => item.nameOfLocation);
  const filteredInvalidStopAddresses = invalidAddresses.filter((item) => item.manifestSequence);
  const render = () => {
    return (
      <StopList data-testid="stop-list">
        <Title>{"Route optimization request contains invalid addresses: "}</Title>
        {filteredInvalidLocation.length > 0 &&
          filteredInvalidLocation.map((x) => (
            <StopItem data-testid="location-item" key={`${x.nameOfLocation}`}>
              <StopNumber data-testid="location-type">{`${x.nameOfLocation}: `}</StopNumber>
              <DialogText data-testid="location-text" primary>
                {x.address}
              </DialogText>
            </StopItem>
          ))}
        {filteredInvalidStopAddresses.length > 0 &&
          filteredInvalidStopAddresses.map((x) => (
            <StopItem data-testid="stop-item" key={`${x.manifestSequence}-${x.address}`}>
              <StopNumber data-testid="stop-number">{`Stop #${x.manifestSequence},`}</StopNumber>
              <DialogText data-testid="stop-text" primary>
                {x.address}
              </DialogText>
            </StopItem>
          ))}
      </StopList>
    );
  };
  return (
    <InvalidContent data-testid="invalid-content">
      <DialogText primary>{"Optimization isn't possible due to following issues: "}</DialogText>
      {render()}
      <DialogText primary>{"Please make the necessary adjustments and click 'Optimize' again."}</DialogText>
      <DialogText primary>
        {"Take note of this information as it won’t be available anymore once you close this window."}
      </DialogText>
    </InvalidContent>
  );
};
interface InvalidGraphhopper {
  graphHopperInvalidMess: GraphHopperInvalidStopMess[];
  graphHopperValidMess: GraphHopperValidStopMess[][];
  graphHopperInvalidLocationMess: GraphHopperInvalidLocationMess[];
}

const InvalidGraphhopper = ({
  graphHopperInvalidMess,
  graphHopperValidMess,
  graphHopperInvalidLocationMess
}: InvalidGraphhopper) => {
  const [showOpenStreetLinkMsg, setShowOpenStreetLinkMsg] = useState(false);

  useEffect(() => {
    const hasInvalidAddressMessage = (messages: GraphHopperInvalidLocationMess[] | GraphHopperInvalidStopMess[]) =>
      messages.length > 0 && messages.some((item) => item.message === GRAPHHOPPER_INVALID_MSG.ADDRESS);
    if (hasInvalidAddressMessage(graphHopperInvalidLocationMess) || hasInvalidAddressMessage(graphHopperInvalidMess)) {
      setShowOpenStreetLinkMsg(true);
    }
  }, [graphHopperInvalidMess, graphHopperInvalidLocationMess]);

  const renderMess = () => {
    if (graphHopperInvalidLocationMess.length > 0) {
      return (
        <LocationErrorContainer>
          <Title>{graphHopperInvalidLocationMess?.at(0)?.message}:</Title>
          {graphHopperInvalidLocationMess?.map((x) => (
            <LocationErrorItem key={`${x.serviceId}-${x.address}`}>
              <DialogText primary>
                The {x.serviceId} location [{x.address}] is being rejected by our address validator. Please review this
                address.
              </DialogText>
            </LocationErrorItem>
          ))}
        </LocationErrorContainer>
      );
    }
    if (graphHopperInvalidMess.length > 0) {
      return (
        <>
          <StopList data-testid="stop-list">
            <Title data-testid="stop-title">{graphHopperInvalidMess?.at(0)?.message}:</Title>
            {graphHopperInvalidMess?.map((x) => (
              <StopItem data-testid="stop-item" key={`${x.stopId}-${x.address}`}>
                <StopNumber data-testid="stop-number">{`Stop #${x.stopId},`}</StopNumber>
                <DialogText data-testid="stop-text" primary>
                  {x.address}
                </DialogText>
              </StopItem>
            ))}
          </StopList>
        </>
      );
    }
    if (graphHopperValidMess.length > 0) {
      return (
        <>
          {graphHopperValidMess?.map((mess, index) => {
            const reason = mess?.at(0)?.reason;
            const title = capitalizeWords(reason || "");
            return (
              <>
                <StopList data-testid="stop-list" key={index}>
                  <Title data-testid="stop-title">{title}:</Title>
                  {mess?.map((x) => (
                    <StopItem data-testid="stop-item" key={`${x.id}-${x.address}`}>
                      <StopNumber data-testid="stop-number">{`Stop #${x.stopId},`}</StopNumber>
                      <DialogText data-testid="stop-text" primary>
                        {x.address}
                      </DialogText>
                    </StopItem>
                  ))}
                </StopList>
              </>
            );
          })}
        </>
      );
    }
    return <div></div>;
  };
  return (
    <InvalidContent data-testid="invalid-content">
      <DialogText primary>{"Optimization isn't possible due to following issues: "}</DialogText>
      {renderMess()}
      <DialogText primary>{"Please make the necessary adjustments and click 'Optimize' again."}</DialogText>
      {showOpenStreetLinkMsg ? (
        <OpenStreetMsgContainer data-testid={"open-street-msg"}>
          <DialogText primary>
            {
              "If you are confident that the address(es) are accurate, it’s likely that our 3rd party optimization engine doesn’t yet have this address in their database. If this is a location you’ll visit frequently, we’d advise adding it to the OpenStreetMap database by clicking "
            }
            <a target="_blank" rel="noreferrer" href={"https://openstreetmap.org"}>
              this link.
            </a>
          </DialogText>
          <DialogText primary>
            {
              "This will require creating an account and taking a minute to learn how to add an address. After a success or two, it should be very quick. Expect up to 72 hours for the address to be added to their database. "
            }
          </DialogText>
        </OpenStreetMsgContainer>
      ) : (
        <DialogText primary>
          {"Take note of this information as it won’t be available anymore once you close this window."}
        </DialogText>
      )}
    </InvalidContent>
  );
};
interface GeneticErrorProps {
  graphHopperGeneticMess: string;
}
const GeneticError = ({graphHopperGeneticMess}: GeneticErrorProps) => {
  return (
    <InvalidContent data-testid="invalid-content">
      <DialogText data-testid="genetic-message" primary>
        {graphHopperGeneticMess}
      </DialogText>
    </InvalidContent>
  );
};
const InvalidContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
const StopList = styled.ul`
  padding-left: 10px;
  margin: 0px;
`;
const StopItem = styled.li`
  list-style-type: "\1F44D";
  font-weight: 600;
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-left: 10px;
`;
const StopNumber = styled.span`
  font-weight: bold;
  font-size: 14px;
  font-family: Roboto;
  margin-right: 5px;
  width: fit-content;
`;

const LocationErrorContainer = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding-left: 12px;
`;

const LocationErrorItem = styled.li`
  list-style-type: "\1F44D";
`;

const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 14px;
  font-family: Roboto;
  width: fit-content;
  margin-left: -10px;
  margin-bottom: 10px;
`;

const OpenStreetMsgContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
`;
