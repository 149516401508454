import {format, formatInTimeZone, toDate} from "date-fns-tz";
import {Maybe} from "../generated/graphql";
import {startOfDay} from "date-fns";
import {DateFormats} from "../components/common/Constants";

export type UnknownString = Maybe<string> | string | undefined;

interface TimeFormatOptions {
  includeZone?: boolean;
  amPmFormat?: string;
}

const IANATimeZoneMapping: Record<string, string> = {
  EST: "America/New_York",
  CST: "America/Chicago",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
  HST: "Pacific/Honolulu",
  AKST: "America/Anchorage",
  AKS: "America/Anchorage",
  NST: "America/St_Johns",
  AST: "America/Halifax",
  ATL: "America/Halifax",
  ICT: "Asia/Bangkok"
};

export const getIANATimezone = (timezone: string) => {
  return IANATimeZoneMapping[timezone] ?? timezone;
};

const getLocalDate = (dateStr: UnknownString | Date, timeZone: UnknownString, formatStr?: string) => {
  if (dateStr && timeZone) {
    const date = toDate(dateStr, {timeZone: timeZone});
    return format(date, formatStr ? formatStr : "M/d/yy");
  }
  return null;
};

const getLocalTime = ({
  dateStr,
  isTimeFormat24hr,
  options,
  timeZone
}: {
  dateStr: UnknownString | Date;
  timeZone?: UnknownString;
  isTimeFormat24hr: boolean;
  options?: TimeFormatOptions;
}) => {
  if (dateStr && timeZone) {
    const date = toDate(dateStr, timeZone ? {timeZone: timeZone} : {});
    const zone = options?.includeZone ? " z" : "";
    let hr = "h";
    let ampm = options?.amPmFormat ? ` ${options.amPmFormat}` : " aa";
    if (isTimeFormat24hr) {
      hr = "HH";
      ampm = "";
    }
    return format(date, `${hr}:mm${ampm}${zone}`);
  }
  return "";
};

const getLocalDateTime = ({
  dateStr,
  timeZone,
  isTimeFormat24hr,
  timeFormat = ""
}: {
  dateStr: UnknownString;
  timeZone?: UnknownString;
  isTimeFormat24hr: boolean;
  timeFormat?: string;
}) => {
  if (dateStr) {
    const date = toDate(dateStr, timeZone ? {timeZone: timeZone} : {});
    const timeFormatString = ["M/d/yy"];
    if (isTimeFormat24hr) {
      timeFormatString.push("HH:mm");
    } else {
      timeFormatString.push("h:mm aa");
    }
    timeFormatString.push("z");

    return format(date, timeFormat ? timeFormat : timeFormatString.join(" "));
  }
  return "";
};

function removeTimeFromDate(inputDate: Date): Date {
  const dateWithoutTime = startOfDay(inputDate);
  return dateWithoutTime;
}

const getTimeAtSpecificTimeZone = ({
  dateStr,
  isTimeFormat24hr,
  timeZone,
  options
}: {
  dateStr: UnknownString;
  timeZone: UnknownString;
  isTimeFormat24hr: boolean;
  options?: TimeFormatOptions;
}) => {
  if (dateStr && timeZone) {
    const zone = options?.includeZone ? " zzz" : "";
    let hr = "h";
    let ampm = options?.amPmFormat ? ` ${options.amPmFormat}` : " aa";
    if (isTimeFormat24hr) {
      hr = "HH";
      ampm = "";
    }
    return formatInTimeZone(dateStr, getIANATimezone(timeZone), `${hr}:mm${ampm}${zone}`);
  }
  return "";
};

const getJobStopDate = (dateStr: UnknownString, timeZone: UnknownString, formatStr?: string) => {
  if (dateStr && timeZone) {
    return formatInTimeZone(dateStr, getIANATimezone(timeZone), formatStr ?? "M/d/yy");
  }
  return "";
};

const getDateTimeAtSpecificTimeZone = ({
  dateStr,
  timeZone,
  isTimeFormat24hr,
  timeFormat = ""
}: {
  dateStr: UnknownString;
  timeZone: UnknownString;
  isTimeFormat24hr: boolean;
  timeFormat?: string;
}) => {
  if (dateStr && timeZone) {
    const timeFormatString = ["M/d/yy"];
    if (isTimeFormat24hr) {
      timeFormatString.push("HH:mm");
    } else {
      timeFormatString.push("h:mm aa");
    }
    timeFormatString.push("zzz");

    return formatInTimeZone(dateStr, getIANATimezone(timeZone), timeFormat ? timeFormat : timeFormatString.join(" "));
  }
  return "";
};

const formatCMSDateTimeInTimeZone = (dateData: string | number | Date, timeZone: string, formatStr?: string) => {
  if (dateData && timeZone) {
    return formatInTimeZone(dateData, getIANATimezone(timeZone), formatStr ?? DateFormats.awsDateTimeWithOffset);
  }
  return undefined;
};

const isValidDateTime = (dateTime: any) => {
  if (typeof dateTime === "string") {
    return Date.parse(dateTime) > 0;
  }
  return Boolean(dateTime && !isNaN(dateTime));
};

const formatDateStringMDYToYDM = (inputDateString: string) => {
  const dateRegex = /^\d{2}\/\d{2}\/\d{4}$/;
  if (!dateRegex.test(inputDateString)) {
    return inputDateString;
  }
  const parts = inputDateString.split("/");
  const formattedDate = `${parts[2]}-${parts[0].padStart(2, "0")}-${parts[1].padStart(2, "0")}`;
  return formattedDate;
};

export {
  getLocalDateTime,
  getLocalTime,
  getLocalDate,
  removeTimeFromDate,
  getTimeAtSpecificTimeZone,
  getJobStopDate,
  getDateTimeAtSpecificTimeZone,
  formatCMSDateTimeInTimeZone,
  isValidDateTime,
  formatDateStringMDYToYDM
};
