import styled from "@emotion/styled";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useContext} from "react";
import {ManifestStop, NestedJobStop} from "../../../generated/graphql";
import {ServiceBadge} from "../../job/ServiceBadge";
import {isActive, isCompleted} from "../../../services/JobStopService";
import {getStopPrepositionForType, getStopTimeForType} from "../../../utils/StopTime";
import {EntityVisibilityLevel} from "../MapVisibilityContext";
import {ManifestStopEnhanced} from "../../manifest/details/ManifestDetailsV2";
import {PreferenceContext, extractSimplePref} from "../../../providers/PreferenceProvider";
import {TenantPreferences} from "../../common/Constants";
import {useAppContext} from "../../../ApplicationContext";
import {UnknownString, getDateTimeAtSpecificTimeZone, getLocalDateTime} from "../../../utils/DateUtils";

interface StopInfoProps {
  info: StopInfoMetadata;
  stop: NestedJobStop | ManifestStopEnhanced;
  color: string;
}

export interface StopInfoMetadata {
  key: string;
  jobNumber: string;
  customer: string;
  service: string;
  visibilityLevel: EntityVisibilityLevel;
  routeColor?: string;
  isFirstStop: boolean;
}

const StopInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 290px;
  row-gap: 10px;
  padding-bottom: 8px;
`;

const Title = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-left: 3px;
`;

const Footer = styled.div<{stopStatus: string}>`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding-top: 5px;
  padding-left: 3px;
  font-weight: 700;
  color: ${(props) => (props.stopStatus === "C" ? "#25A567" : "#777777")};
`;

const StopTime = styled.div<{stopStatus: string}>`
  color: ${(props) => (props.stopStatus === "C" ? "#777777" : "#000000")};
`;

const StopTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  max-width: 180px;
  padding-left: 8px;
`;

const StopSequenceCounter = styled.span`
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
`;

const Address = styled.div`
  display: flex;
  flex-direction: column;
  color: #777777;
  max-width: 200px;
`;

const JobNumber = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: right;
  align-items: flex-end;
  padding-right: 10px;
  color: #777777;
  font-weight: 500;
  max-width: 200px;
`;

const ServiceContainer = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: right;
  align-items: flex-end;
`;

const renderStopTitle = (stop: NestedJobStop | ManifestStop) => {
  switch (stop.stopType) {
    case "P":
      if (isActive(stop) || isCompleted(stop)) {
        return <div>PICKED UP</div>;
      } else {
        return <div>PICK UP</div>;
      }
    case "D":
      if (stop.jobStopStatus === "C") {
        return <div>DELIVERED</div>;
      } else {
        return <div>DROP OFF</div>;
      }
    case "B":
      if (stop.jobStopStatus === "C") {
        return (
          <div>
            <div>DROPPED OFF</div>
            <div>PICKED UP</div>
          </div>
        );
      } else {
        return (
          <div>
            <div>DROP OFF</div>
            <div>PICK UP</div>
          </div>
        );
      }
  }
};

const getStopInfoDateTimeFormat = (preposition: string | null, isTimeFormat24hr: boolean) => {
  const timeFormat = ["MMM d"];
  if (preposition) timeFormat.push(`'${preposition}'`);
  if (isTimeFormat24hr) {
    timeFormat.push("HH:mm");
  } else {
    timeFormat.push("h:mm aaa");
  }
  timeFormat.push("zzz");

  return timeFormat.join(" ");
};

const renderStopTime = (
  stop: NestedJobStop | ManifestStop,
  getDateTimeFnc: ({
    dateStr,
    timeZone,
    isTimeFormat24hr,
    timeFormat
  }: {
    dateStr: UnknownString;
    timeZone: UnknownString;
    isTimeFormat24hr: boolean;
    timeFormat?: string;
  }) => string | null,
  isTimeFormat24hr: boolean
) => {
  if (stop.timeZone) {
    const pickUpTime = getStopTimeForType(stop, "P")!;
    const pickupPrep = getStopPrepositionForType(stop, "P");
    const pickupTimeFormat = getStopInfoDateTimeFormat(pickupPrep, isTimeFormat24hr);

    const deliveryTime = getStopTimeForType(stop, "D")!;
    const deliveryPrep = getStopPrepositionForType(stop, "D");
    const deliveryTimeFormat = getStopInfoDateTimeFormat(deliveryPrep, isTimeFormat24hr);

    const pickupDateTimeString = getDateTimeFnc({
      dateStr: pickUpTime,
      isTimeFormat24hr,
      timeZone: stop.timeZone,
      timeFormat: pickupTimeFormat
    });
    const deliveryDateTimeString = getDateTimeFnc({
      dateStr: deliveryTime,
      isTimeFormat24hr,
      timeZone: stop.timeZone,
      timeFormat: deliveryTimeFormat
    });

    if (stop.stopType === "B") {
      return (
        <div>
          <StopTime stopStatus={stop.jobStopStatus as string}>{pickupDateTimeString}</StopTime>
          <StopTime stopStatus={stop.jobStopStatus as string}>{deliveryDateTimeString}</StopTime>
        </div>
      );
    }
    return (
      <StopTime stopStatus={stop.jobStopStatus as string}>
        {stop.stopType === "P" ? pickupDateTimeString : deliveryDateTimeString}
      </StopTime>
    );
  }
  return <div />;
};

export const getStopSequence = (stop: NestedJobStop | ManifestStopEnhanced) => {
  return stop.__typename === "ManifestStop" && stop.optimizedSequence
    ? stop.optimizedSequence
    : stop.manifestSequence ?? stop.sequence;
};

const StopInfo = ({info, stop, color}: StopInfoProps) => {
  const {tenantPreferences} = useContext(PreferenceContext);
  const {isTimeFormat24hr} = useAppContext().appState;
  const timezoneOfJobStop = extractSimplePref(tenantPreferences, TenantPreferences.timezoneOfJobStop, false).value;
  const getStopDateTimeFnc = timezoneOfJobStop ? getDateTimeAtSpecificTimeZone : getLocalDateTime;

  const stopSequence = getStopSequence(stop);

  return (
    <StopInfoContainer data-testid={`stop-info-${stop.jobStopId}`}>
      <Title>
        <span className="fa-stack">
          <FontAwesomeIcon size={"sm"} className="fa-stack-2x" icon={solid("circle")} color={color} />
          <StopSequenceCounter className="fa-stack-1x">{stopSequence}</StopSequenceCounter>
        </span>
        <StopTitle>{stop.name}</StopTitle>
        <ServiceContainer>
          <ServiceBadge service={info.service} />
        </ServiceContainer>
      </Title>
      <Info>
        <Address>
          <div>{info.customer}</div>
          <div>{stop.address}</div>
          <div>
            {stop.city}, {stop.state}, {stop.dispatchZone}
          </div>
        </Address>
        <JobNumber>Job {info.jobNumber}</JobNumber>
      </Info>
      <Footer stopStatus={stop?.jobStopStatus as string}>
        <div>{renderStopTitle(stop)}</div>
        <div style={{paddingLeft: "8px"}}>{renderStopTime(stop, getStopDateTimeFnc, isTimeFormat24hr)}</div>
      </Footer>
    </StopInfoContainer>
  );
};

export default StopInfo;
