import React from "react";
import {DoubleFieldCellValue, StackedFieldCellRenderer} from "./DoubleFieldCellRenderer";
import {NestedJobStop} from "../../../generated/graphql";
import {getStopPreposition, getStopTime} from "../../../utils/StopTime";
import {getJobStopDate, getTimeAtSpecificTimeZone, getLocalDate, getLocalTime} from "../../../utils/DateUtils";
import {ICellRendererParams} from "@ag-grid-community/core";
import {ETAStop} from "../../manifest/details/ManifestDetailsReducer";

export interface GetTimeCellValueProps {
  data: NestedJobStop | ETAStop;
  is24HourFormat?: boolean;
  timezoneOfJobStop?: boolean;
}

export const getStopTimeCellValue = ({timezoneOfJobStop, is24HourFormat, data}: GetTimeCellValueProps) => {
  const stopTime = getStopTime(data);
  if (!stopTime) {
    return undefined;
  }

  let localTime = timezoneOfJobStop
    ? getTimeAtSpecificTimeZone({
        dateStr: stopTime.toISOString(),
        timeZone: data.timeZone,
        isTimeFormat24hr: is24HourFormat ?? false,
        options: {includeZone: true}
      })
    : getLocalTime({
        dateStr: stopTime,
        timeZone: data.timeZone,
        isTimeFormat24hr: is24HourFormat ?? false,
        options: {includeZone: true}
      });

  const stopPrep = getStopPreposition(data);
  if (stopPrep) {
    localTime = `${stopPrep} ${localTime}`;
  }

  const localDate = timezoneOfJobStop
    ? getJobStopDate(stopTime.toISOString(), data.timeZone, "MMM dd")
    : getLocalDate(stopTime, data.timeZone, "MMM dd");

  const doubleFieldValue: DoubleFieldCellValue = {
    upperField: {
      value: localTime,
      style: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#111827"
      }
    },
    lowerField: {
      value: localDate!,
      style: {
        fontSize: "14px",
        fontWeight: "lighter",
        color: "#6B7280"
      }
    }
  };

  return doubleFieldValue;
};

export const getETATimeCellValue = ({timezoneOfJobStop, is24HourFormat, data}: GetTimeCellValueProps) => {
  const time = (data as ETAStop).eta;
  if (!time) {
    return undefined;
  }

  const localTime = timezoneOfJobStop
    ? getTimeAtSpecificTimeZone({
        dateStr: time,
        timeZone: data.timeZone,
        isTimeFormat24hr: is24HourFormat ?? false,
        options: {includeZone: true}
      })
    : getLocalTime({
        dateStr: time,
        timeZone: data.timeZone,
        isTimeFormat24hr: is24HourFormat ?? false,
        options: {includeZone: true}
      });

  const localDate = timezoneOfJobStop
    ? getJobStopDate(time, data.timeZone, "MMM dd")
    : getLocalDate(time, data.timeZone, "MMM dd");

  const doubleFieldValue: DoubleFieldCellValue = {
    upperField: {
      value: localTime,
      style: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#111827"
      }
    },
    lowerField: {
      value: localDate!,
      style: {
        fontSize: "14px",
        fontWeight: "lighter",
        color: "#6B7280"
      }
    }
  };

  return doubleFieldValue;
};

export const getETAGeneratedDateTimeCellValue = ({is24HourFormat, data}: GetTimeCellValueProps) => {
  const time = (data as ETAStop).etaGeneratedDateTime;
  if (!time) {
    return undefined;
  }

  const localTime = getLocalTime({
    dateStr: time,
    timeZone: data.timeZone,
    isTimeFormat24hr: is24HourFormat ?? false,
    options: {includeZone: true}
  });

  const localDate = getLocalDate(time, data.timeZone, "MMM dd");

  const doubleFieldValue: DoubleFieldCellValue = {
    upperField: {
      value: localTime,
      style: {
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16px",
        color: "#111827"
      }
    },
    lowerField: {
      value: localDate!,
      style: {
        fontSize: "14px",
        fontWeight: "lighter",
        color: "#6B7280"
      }
    }
  };

  return doubleFieldValue;
};

export const TimeCellRenderer = (params: ICellRendererParams<DoubleFieldCellValue | undefined>) => {
  if (!params.value) {
    return <></>;
  }
  return StackedFieldCellRenderer(params.value);
};
